import React, { Component } from 'react';
import {Link} from 'react-router-dom' 
class Design extends Component {
    render() {
        return (
            <>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
        <div class="firefly"></div>
            <div className="animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
        <div class="box">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div class="content">
            <div className={this.props.data.iconbox}>
                    <img style={{margin: 10}} src={this.props.data.imgsrc}></img>
                        <h2 className="title color-d11">{this.props.data.title}</h2>
          <p>
                            {this.props.data.text01}<br />{this.props.data.text02}
          </p>
        </div>
        
      </div>
                </div>
            </div>
            </>
        );
    }
}

export default Design;
