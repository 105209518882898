const menus = [
    {
        id: 1,
        tomenu: "#about",
        namemenu: "About"
    },
    {
        id: 2,
        tomenu: "#services",
        namemenu: "services"
    },
    {
        id: 3,
        tomenu: "#portfolio",
        namemenu: "Portfolio"
    }
];



export default menus ;