import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Banner extends Component {
    render() {
        return (
            <section className="banner-section s4 two-home" id="home">
                <div className="container">
                    <div className="content-text position-relative">
                        <div className="animate-element wow delay5 fadeInDown" data-wow-delay="0.3s">
                            <div className="tag color-d6 lt-sp53 mg-b34">Programador</div>
                            <h1 className="cd-headline clip is-full-width title color-d11 mg-b41">
                                <span>Habil Con</span><br/>
                                <span className="cd-words-wrapper color-d6">
                                <b className="is-visible"> React 🚀</b>
                                <b> Firebase 🔥 </b>
                                <b> PHP 🐘 </b>
                                <b> Node.js! 💻</b>
                                <b> React-Native 📱</b>
                                <b> ECMAS6 </b>
                                </span>
                            </h1>
                            <p className="color-d11 lt-sp02 mg-b51">
                                Hola, soy Alejandro Pereira programdor web con 4 años <br />de experiencia. En qué puedo ayudarte?
                            </p>
                        </div>
                        <div className="animate-element wow delay5 fadeInUp" data-wow-delay="0.3s">
                            <div className="fl-btn btn-general color-s2 btn-hv-border">
                                <a href="https://wa.me/send?phone=584144117131"  className="f-w500 color-d11 border-corner5 lt-sp01 text-two">Hablemos por WhatsApp 📞</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Banner;
