import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            <div className="tf-modal-content justify-content-end"> 
                <footer id="footer" className="footer footer-s4">
                    <div className="container">
                        <div id="footer-widget" className="footer-widget-s3 border-bottom-s2 position-relative">
                            <h3 className="widget-title larger lt-sp06 text-white text-center">Conoceme!</h3>
                            <div className="site-list site-list-center text-center">
                                <a href="https://fb.me/ofsure" className="bg-s1"><i className="fa fa-facebook" aria-hidden="true" /></a>
                                <a href="https://linkedin.com/in/alejandro-pereira-14405810b/" className="bg-s2"><i className="fa fa-linkedin" aria-hidden="true" /></a>
                                <a href="https://wa.me/send?phone=584144117131" className="bg-s3"><i className="fa fa-whatsapp" aria-hidden="true" /></a>
                            </div>
                        </div>
                    </div>
                    <div id="bottom" className="bottom-s3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="copyright lt-sp02">
                                        © Alew140, 2021 All rights reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
