import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Design, NumberCount} from './specilizing/index';

class Specilizing extends Component {
    constructor(props){
        super(props);
        this.state = {
            design : [
                {
                    id: 1,
                    iconbox: '',
                    iconcolor: 'iconbox-icon color1',
                    imgsrc: '/images/section/20.png',
                    title: 'Remoto',
                    text01: "Trabajo Remoto Pero Comprometido.",
                    text02: ' Llevo acabo las tareas sin excusas personales.'
                },
                {
                    id: 2,
                    iconbox: '',
                    iconcolor: 'iconbox-icon color2 color1',
                    imgsrc: '/images/section/21.png',
                    title: 'Propiedad Colectiva',
                    text01: "Me tomo todos los proyectos muy en serio",
                    text02: ' y los llevo acabo como si fueran míos.'
                },
                {
                    id: 3,
                    iconbox: ' ',
                    iconcolor: 'iconbox-icon color3',
                    imgsrc: '/images/section/22.png',
                    title: 'Empatía',
                    text01: "Trabajar con diversos equipos de trabajo,",
                    text02: ' Me eseñó a dejar el ego a un lado. ( SCRUM / XP )'
                },
            ]
        }
    }
    render() {
        return (
            <div className="section slide-personal-Intro-third slide-dark" >
                <section className="specilizing-in s1" id="services">
                    <div className="container">
                        <div className="flat-title">
                            <h2 className="title-section color-d11 animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">Mis Aptitudes más Relevantes</h2>
                        </div>
                        <div className="cards">
                            {
                                this.state.design.map(data => (
                                    <Design key={data.id} data={data}/>
                                ))
                            }
                            <div className="flat-spacer" data-desktop="0" data-mobile="0" data-smobile="0"></div>
                          
                        </div>
                    </div>
                    {/* <div className="featured-post animate-element wow delay5 fadeInRight" data-wow-delay="0.5s">
                        <img src="images/section/09.png" alt="images" />
                    </div> */}
                </section>
            </div>
        );
    }
}

export default Specilizing;
