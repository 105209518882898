import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Icon } from '../home02/about/index';
import { NumberCount } from './about/index';

class About extends Component {
    render() {
        return (
            <section className="about-me t2 s3 shape-am position-relative" id="about">
                <div className="section-icons">
                    <Icon /> 
                </div>
                <div className="container">
                <div className="row position-relative">
                    <div className="col-lg-7 animate-element wow delay5 fadeInDown">
                        <div className="flat-title t2">
                            <h4 className="sub-title mg-b22">Breve Descripción</h4>
                            <h2 className="title-section color-d11 mg-b41">Acerca de mi</h2>
                            <div className="description">
                                <p className="color-d13 lt-sp01">
                                A lo largo de mi vida he pasado por circunstancias que te hacen centrarte y definirte
                                como persona, hoy en día soy un joven programador proactivo y calificado con 5 años de experiencia 
                                que ha superado infinidad de retos laborales.
                                 
                                </p>
                                <p className="color-d13 lt-sp01">
                                He conseguido más de 15 certificaciones 
                                en distintas areas de tecnología en distintas academias, muchas de ellas gracias a mi beca en Platzi.com
                                Dandome la oportunidad de alcanzar el cargo de CTO en la mayoría de las empresas en las que tuve la oportunidad de formar parte.
                                Haciendome así un buen prospecto, responsable y determinado.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="fact">
                        <NumberCount />
                        <div className="btn-contact bg-s2 text-center">
                            <h4 className="title color-d11">Escribeme por email</h4>
                            <Link to="#" className=" color-s2 color-d15">Proyectos@alejandropereira.com</Link>
                        </div>
                    </div>
                </div>
                </div>
                <div className="featured-post animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                <img src="images/section/51.png" alt="images" />
                </div>
            </section>
        );
    }
}

export default About;
