import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class TopEducation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contenttop : [
                {
                    id: 1,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.3s',
                    year: 'Platzi',
                    title: 'Carreras y Cursos Terimanados',
                    text: "Programación Básica \n "+
                          "Introducción a PHP \n "+
                          "Carrera de Desarrollo con Wordpress \n "+
                          "Firebase para la Web \n "+
                          "Carrera de Desarrollo con React \n "+
                          "Desarrollo de Video Juegos con Unity "
                },
                {
                    id: 2,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.5s',
                    year: '2017',
                    title: 'Micro Tecnologia Neumatica',
                    text: 'Conseguí mi primer empleo como CTO en micro tecnologia neumatica a los 19 años.'
                },
                {
                    id: 3,
                    classname: 'experience-box t3',
                    datawow: '0.7s',
                    year: '2018-2019',
                    title: 'Desarrolle Una App Web',
                    text: 'Entre a PayPerPropects.com como desarrollador freelancer y desarrolle una app usando Wordpress como Framework. Tiene la capacidad de manejar de clientes, identidades Privadas y generado de PDFs.\n ( AÚN EN FUNCIONAMIENTO )'
                }
            ],
            contentbot : [
                {
                    id: 1,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.8s',
                    year: '2019-2020',
                    title: 'CTO',
                    office: 'Pay Per Prospects',
                    text: 'Fuí promido a CTO en PayPerProspects.com y haciendo uso de mi app previa con una integracion de React, Usamos la API de twilio para comunicar clientes SMS-WEB en grupos supervisados.'
                },
                {
                    id: 2,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '1s',
                    year: '2020 - 2020',
                    title: 'Freelancer',
                    office: '',
                    text: 'Desarrolle Multiples Plugins entre esos un Popular plugin de Peru para Pagos Yape y trabajos por contratos para varios clientes.'
                },
                {
                    id: 3,
                    classname: 'experience-box t3',
                    datawow: '1.2s',
                    year: '2020 - Actualidad',
                    title: 'CTO',
                    office: 'Green Department',
                    text: 'Recomendado por mis atiguos empleos fuí contratado como CTO en GreenDepartment.org Creando una App Web haciendo uso de React desarrolle una app para administrar clientes, estiamdos, generado de PDFs entre otras funciones con Firebase. '
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="animate-element delay5 fadeInUp">
                <div className="padding-bottom">
                    <div className="row">
                        {
                            this.state.contenttop.map(data => (
                                <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                    <div className={data.classname}>
                                        <div className="box-inner">
                                        <h3 className="f-info bg-s2 color-d6">{data.year}</h3>
                                        <div className="exp-wrap">
                                            <div className="s-info color-d11 mg-b11">{data.title}</div>
                                            <p  className="color-d13 texto">
                                            {data.text}
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="row">
                    {
                        this.state.contentbot.map(data => (
                            <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                <div className={data.classname}>
                                    <div className="box-inner">
                                    <h3 className="f-info bg-s2 color-d6">{data.year}</h3>
                                    <div className="exp-wrap">
                                        <div className="s-info color-d11 mg-b11">{data.title} <Link to="#">{data.office}</Link></div>
                                        <p className="color-d13">
                                            {data.text} 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default TopEducation;
